$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #111 !default;
$background-color: #fdfdfd !default;
$brand-color: #2a7ae2 !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

$mobile-max-width: 600px;
$mobile-only: "only screen and (max-width: "$mobile-max-width")";

@mixin relative-font-size($ratio) {
    font-size: $base-font-size * $ratio;
}

$code-font-size: 15px;

#post-content {
    p {
        line-height: 1.8;
        margin-bottom: 1.5em;
        word-break: break-word;
    }

    /**
 * Images
 */
    img {
        max-width: 100%;
        vertical-align: middle;
        height: auto;
    }

    /**
   * Figures
   */
    figure > img {
        display: block;
    }

    figcaption {
        text-align: center;
        font-size: 16px;
        color: gray;
    }

    /**
 * Blockquotes
 */
    blockquote {
        color: $grey-color;
        border-left: 4px solid $grey-color-light;
        padding-left: $spacing-unit / 2;
        @include relative-font-size(1.125);
        letter-spacing: -1px;
        font-style: italic;

        > :last-child {
            margin-bottom: 0;
        }
    }

    /**
 * Code formatting
 */
    .highlight{
        border-radius: 5px;
    }

    pre {
        padding: 13px 17px;
    }

    code.highlighter-rouge{
        color: #242729;
        background-color: #e4e6e8;
        padding: 3px;
        padding-left: 7px;
        padding-right: 7px;
        border-radius: 3px;
        white-space: nowrap;

        @media #{$mobile-only}{
            white-space: normal;
        }
    }

    .highlight,
    code,
    pre,
    pre span,
    code span {
        font-size: $code-font-size;
        font-family: monospace;
    }

    /**
 * Tables
 */
    table {
        margin-bottom: $spacing-unit;
        width: 100%;
        text-align: $table-text-align;
        color: lighten($text-color, 18%);
        border-collapse: collapse;
        border: 1px solid $grey-color-light;
        tr {
            &:nth-child(even) {
                background-color: lighten($grey-color-light, 6%);
            }
        }
        th,
        td {
            padding: ($spacing-unit / 3) ($spacing-unit / 2);
        }
        th {
            background-color: lighten($grey-color-light, 3%);
            border: 1px solid darken($grey-color-light, 4%);
            border-bottom-color: darken($grey-color-light, 12%);
        }
        td {
            border: 1px solid $grey-color-light;
        }
    }

    .image-caption, .caption {
        text-align: center;
        font-size: 16px;
        color: gray;
    }
    
    // Make Math scrollable:
    .katex-display {
        overflow-x: auto;
        overflow-y: hidden;
    }
    
    
    .alert p {
        margin: 0;
        margin-bottom: 0;
    }
}

