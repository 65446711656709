body {
    padding-top: 70px;
    font-family: 'Roboto', sans-serif;
}

.headline, .lead{
    text-align: center;
    color: white;
}

.navbar{
    padding: 15px;
    background-color: white;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
}

.navbar-toggler:focus {
    outline: none;
}

.jumbotron{
    background: rgb(43,94,255);
    background: linear-gradient(22deg, rgba(43,94,255,1) 45%, rgba(0,212,255,1) 100%);
    border-radius: 0;
}

.jumbotron .container{
    max-width: 900px;
}

.jumbotron p{
    margin-bottom: 0;
}

.lead{
    opacity: 0.9;
}

.lead p{
    opacity: 0.9;
}

.headline, .lead{
    text-align: center;
    color: white;
}

main{
    padding-bottom: 40px;
}

main p, /*main span,*/ main ul, main ol, main div {
    font-size: 19px;
}

.footer .container{
    padding-top: 8px;
    padding-bottom: 8px;
}

#footer-nav-menu{
    padding-bottom: 30px;
}

.meta-tag{
    display: inline-block;
    font-size: 16px;
}

.single-post-item{
    padding: 10px;
    margin-bottom: 25px;
    transition: 0.3s;
}

.single-post-item .entry-meta{
    color: rgba(0,0,0,.5);
}

.single-post-item .meta-tag{
    margin-right: 10px;
}

.single-post-item:hover{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.20);
    border-radius: 5px;
}

.single-post-item .entry-meta{
    margin-bottom: 10px;
}

.single-post-item-content{
    color: black;
}

.single-post-item-content:hover{
    color: black;
    text-decoration: none;
}

.single-post-item-content .entry-title{
    color: #007bff;
}

.post-full-view .entry-meta{
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
}

.post-full-view .entry-meta a{
    color: rgba(255, 255, 255, 0.9);
}

.post-full-view .meta-tag{
    margin-right: 7px;
    margin-left: 7px;
}

pre{
    color: inherit;
}

#feedback-infobox{
    margin-top: 70px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 20px;
    //background: linear-gradient(22deg, rgba(43,94,255,1) 45%, rgba(0,212,255,1) 100%);
    //color: white;
    text-align: center;

    a{
        //color: white;
    }
}

/*Implementing responsive font size for Bootstrap.
Copied from: https://christianoliff.com/blog/bootstrap-with-rfs
*/
@media (max-width: 1200px) {
    legend {
        font-size: calc(1.275rem + 0.3vw);
    }
    h1,
    .h1 {
        font-size: calc(1.375rem + 1.5vw);
    }
    h2,
    .h2 {
        font-size: calc(1.325rem + 0.9vw);
    }
    h3,
    .h3 {
        font-size: calc(1.3rem + 0.6vw);
    }
    h4,
    .h4 {
        font-size: calc(1.275rem + 0.3vw);
    }
    .display-1 {
        font-size: calc(1.725rem + 5.7vw);
    }
    .display-2 {
        font-size: calc(1.675rem + 5.1vw);
    }
    .display-3 {
        font-size: calc(1.575rem + 3.9vw);
    }
    .display-4 {
        font-size: calc(1.475rem + 2.7vw);
    }
    .close {
        font-size: calc(1.275rem + 0.3vw);
    }

    main p, /*main span,*/ main ul, main ol, main div{
        font-size: calc(16px + 0.25vw);
    }
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 900px;
    }
}

@media (min-width: 992px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 900px;
    }
}